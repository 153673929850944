











































































































































import { ProfileModel } from '@/models/profile-model'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { UserItemController } from '@/modules/community/home/components/user-item-controller'
import { walletStore } from '@/stores/wallet-store'
import { userActionController } from '@/modules/common/components/section/most-active-user-section-controller'

@Observer
@Component({
  components: {
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
    avatar: () => import('@/modules/community/common/components/avatar.vue'),
  },
})
export default class UserItem extends Vue {
  @Prop() user!: ProfileModel
  @Prop({ default: false, type: Boolean }) horizontal!: boolean

  controller = new UserItemController()
  userActionController = userActionController
  walletStore = walletStore

  get walletLast4Char() {
    if (this.user.walletAddress) return this.user.walletAddress.slice(-4)
    else return ''
  }

  @Watch('user', { immediate: true })
  async userChanged() {
    if (this.user._id) await this.controller.fetchPosts(this.user._id)
  }

  get maxWidth() {
    const width = this.$vuetify.breakpoint.width

    if (width < 450) {
      return '150px'
    }

    if (450 < width && width < 400) {
      return '200px'
    }
  }
}
