import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { PostsStore } from '@/stores/posts-store'
import { computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class UserItemController {
  // @observable postsStore?: PostsStore
  @observable fetching = false 
  @observable posts: PostStore[] = []

  // @computed get posts() {
  //   return this.postsStore?.posts
  // }

  @asyncAction *fetchPosts(profileId: string) {    
    try {
      this.fetching = true
      const posts = yield apiService.posts.fetchPosts({ _limit: 6, _sort: 'topScore:desc', profile: profileId })
      this.posts = posts.map(item => new PostStore(item))
    } catch (e) {
      snackController.commonError('Something went wrong when fetching posts')
    } finally {
      this.fetching = false
    }
  }
}
