import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getFollowStatsNumberAfterSync, getPostStatsNumberAfterSync } from '@/helper/utils'
import { ProfileModel } from '@/models/profile-model'
import { apiService } from '@/services/api-services'
import { dispatcher, FollowingProfile, PostActionModel } from '@/stores/dispatcher'
import { walletStore } from '@/stores/wallet-store'
import { map } from 'lodash'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { async, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class MostActiveUserSectionController {
  @observable mostActiveUsers: ProfileModel[] = []
  private _unsubcrible = new Subject()
  @observable page = 0
  @observable pageSize = 4
  @observable fetchApiCount = 0
  @observable showMoreLoading = false
  @observable followedUsers: ProfileModel[] = []

  constructor() {
    this.fetchMostActiveUsers()

    // dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
    //   this.syncFollowProfile(profile)
    // })
    dispatcher.$postChanged.pipe(takeUntil(this._unsubcrible)).subscribe((postAction) => {
      this.syncPostAction(postAction)
    })
  }

  @computed get displayedUsers() {
    const start = this.page * this.pageSize
    return this.mostActiveUsers.slice(start, start + this.pageSize)
  }

  @asyncAction *showMore() {
    this.showMoreLoading = true
    if ((this.page + 1) * this.pageSize >= this.mostActiveUsers.length) {
      this.fetchApiCount += 1
      yield this.fetchMostActiveUsers()
    } else {
      this.page += 1
    }
    this.showMoreLoading = false
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }

  // @action syncFollowProfile(profile: FollowingProfile) {
  //   if (!this.mostActiveUsers?.length) return
  //   let userIndex
  //   userIndex = this.mostActiveUsers.findIndex((item) => item._id === profile.to)
  //   if (userIndex !== -1) {
  //     this.mostActiveUsers[userIndex] = {
  //       ...this.mostActiveUsers[userIndex],
  //       isFollowing: profile.followState,
  //       loading: false,
  //       totalFollowers: getFollowStatsNumberAfterSync(
  //         this.mostActiveUsers[userIndex].totalFollowers,
  //         profile.followState
  //       ),
  //     }
  //     return
  //   }
  //   userIndex = this.mostActiveUsers.findIndex((item) => item._id === profile.from)
  //   if (userIndex !== -1) {
  //     let mostActiveUser = this.mostActiveUsers[userIndex]
  //     mostActiveUser = {
  //       ...mostActiveUser,
  //       loading: false,
  //       totalFollowings: getFollowStatsNumberAfterSync(mostActiveUser.totalFollowings, profile.followState),
  //     }
  //   }
  // }

  @action syncPostAction(postAction: PostActionModel) {
    if (!this.mostActiveUsers?.length) return
    const userIndex = this.mostActiveUsers.findIndex((item) => item._id === postAction.owner)
    if (userIndex !== -1) {
      this.mostActiveUsers[userIndex] = {
        ...this.mostActiveUsers[userIndex],
        totalPosts: getPostStatsNumberAfterSync(this.mostActiveUsers[userIndex].totalPosts, postAction.type),
      }
    }
  }

  @asyncAction *fetchMostActiveUsers() {
    try {
      this.mostActiveUsers = yield apiService.profiles.getMostActiveUsers({
        page: this.fetchApiCount,
        pageSize: 30,
        followedUsers: map(this.followedUsers, '_id'),
      })
      this.page = 0
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *followUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.id })
      item.isFollowing = true
      this.followedUsers.push({ ...item })
      this.mostActiveUsers = this.mostActiveUsers.filter((user) => user._id !== item._id)
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @action.bound get4LastCharacter(profile: ProfileModel) {
    const profileWalletAddress = profile?.walletAddress
    if (profileWalletAddress) return profileWalletAddress.substr(profileWalletAddress.length - 4) || ''
    else return ''
  }
}

export const userActionController = new MostActiveUserSectionController()
